// External Dependencies
import { DistrictSsoTypes } from '@presto-assistant/api_types';
import {
  useCallback, useEffect, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowRightIcon from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';
import DialogContentText from '@mui/material/DialogContentText';
import DisableIcon from '@mui/icons-material/RemoveCircleOutline';
import EnableIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import {
  ConfirmationDialog,
  EnhancedAlert,
  ListItemWithSecondaryAction,
  StyledLink,
  SupportLink,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { SHORT_APP_NAME } from 'utils/constants/app';
import { addNotification } from 'state/notifications/actions';
import { classLinkUrl } from 'utils/constants/urls';
import { useIsOpen } from 'hooks/useIsOpen';
import { useMyDistrict, useUpdateSsoTenantId } from 'utils/api/district';
import { useParseToken } from 'utils/cookies';
import { useParsedSearch } from 'hooks/useParsedSearch';
import SsoSignInDialog from 'components/shared/SsoSignInDialog';

// Local Variables
const StyledArrowRightIcon = styled(ArrowRightIcon)({
  color: 'inherit',
  fontSize: '0.8em',
  marginLeft: '0.25em',
}) as typeof ArrowRightIcon;

// Component Definition
const ClassLinkSsoSetting = (): JSX.Element | null => {
  const [shouldPresentEnabledInDialog, setShouldPresentEnabledInDialog] = useState(false);

  const { openSsoDialog } = useParsedSearch();

  const [isEnableSsoDialogOpen, setIsEnableSsoDialogOpen] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const handleToggleIsEnableSsoDialogOpen = useCallback(() => {
    setIsEnableSsoDialogOpen((prevState) => !prevState);
  }, []);

  const {
    data: districtData,
    isLoading: isDistrictLoading,
  } = useMyDistrict();

  const {
    isOpen: isSsoPromptDialogOpen,
    toggleIsOpen: toggleIsSsoPromptDialogOpen,
  } = useIsOpen();

  const parsedToken = useParseToken();

  const ssoTenantId = districtData?.data.data.ssoTenantId;

  const {
    isLoading: isUpdatingSsoTenantId,
    mutate: updateSsoTenantId,
  } = useUpdateSsoTenantId();

  const handleSubmit = useCallback(() => {
    updateSsoTenantId({
      isEnabled: !ssoTenantId,
    }, {
      onSuccess: () => {
        dispatch(addNotification('ClassLink SSO setting updated successfully.', 'success'));
        handleToggleIsEnableSsoDialogOpen();
      },
    });
  }, [
    dispatch,
    handleToggleIsEnableSsoDialogOpen,
    ssoTenantId,
    updateSsoTenantId,
  ]);

  useEffect(() => {
    if (isEnableSsoDialogOpen) {
      setShouldPresentEnabledInDialog(Boolean(ssoTenantId));
    }
    // intentionally leaving out ssoTenantId from this dependency array
    // this allows the dialog content to persist while closing
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEnableSsoDialogOpen]);

  const handleClickEnableSsoButton = useCallback(() => {
    if (parsedToken?.ssoTenantId) {
      handleToggleIsEnableSsoDialogOpen();
    } else {
      toggleIsSsoPromptDialogOpen();
    }
  }, [parsedToken, handleToggleIsEnableSsoDialogOpen, toggleIsSsoPromptDialogOpen]);

  useEffect(() => {
    if (parsedToken?.ssoTenantId && openSsoDialog) {
      setIsEnableSsoDialogOpen(true);
    }
  }, [
    openSsoDialog,
    parsedToken?.ssoTenantId,
  ]);

  useEffect(
    () => {
      if (isSsoPromptDialogOpen) {
        navigate({
          pathname: location.pathname,
          search: '?openSsoDialog=true',
        }, {
          replace: true,
        });
      }
    },
    // do not include location.pathname in this dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSsoPromptDialogOpen, navigate],
  );

  useEffect(() => {
    if (isEnableSsoDialogOpen) {
      navigate({
        pathname: location.pathname,
        search: '',
      }, {
        replace: true,
      });
    }
  }, [
    isEnableSsoDialogOpen,
    navigate,
    location.pathname,
  ]);

  const handleClickNavigateToClassLink = useCallback(() => {
    navigate(`/${PATHS.DISTRICT_ADMIN}/${PATHS.CLASS_LINK_SCHOOLS}`);
  }, [navigate]);

  if (isDistrictLoading) {
    return null;
  }

  return (
    <>
      <ListItemWithSecondaryAction
        primaryText={<>ClassLink SSO Required: <strong>{ssoTenantId ? 'ON' : 'OFF'}</strong></>}
        secondaryAction={{
          alwaysShowButton: true,
          buttonIcon: ssoTenantId ? <DisableIcon /> : <EnableIcon />,
          buttonText: ssoTenantId ? 'Disable' : 'Enable',
          isLoading: isUpdatingSsoTenantId,
          onClick: handleClickEnableSsoButton,
        }}
        secondaryText={(
          <>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              Require teachers and administrators in your district to sign in using
              {' '}
              <StyledLink
                href={classLinkUrl}
                openInNewTab
              >
                ClassLink
              </StyledLink>.
            </Typography>

            {ssoTenantId && (
              <Typography
                color="textSecondary"
                gutterBottom
                variant="body2"
              >
                <StyledLink onClick={handleClickNavigateToClassLink}>
                  Preview Roster
                  <StyledArrowRightIcon />
                </StyledLink>
              </Typography>
            )}
            <div>
              <SupportLink
                buttonText="Learn more about managing ClassLink SSO"
                slug="manage-classlink-single-sign-on"
              />
            </div>
          </>
        )}
      />

      <SsoSignInDialog
        isOpen={isSsoPromptDialogOpen}
        onClose={toggleIsSsoPromptDialogOpen}
        ssoTypeId={DistrictSsoTypes.ClassLink}
      />

      <ConfirmationDialog
        confirmButtonAction={handleSubmit}
        confirmButtonText={shouldPresentEnabledInDialog ? 'Yes, disable' : 'Yes, enable'}
        declineButtonAction={handleToggleIsEnableSsoDialogOpen}
        description={shouldPresentEnabledInDialog
          ? 'This will allow all directors and administrators in your district to sign in using an email and password.'
          : (
            <>
              <DialogContentText gutterBottom>
                Require all directors and administrators in your district to
                sign in using ClassLink instead of entering an email and password.
              </DialogContentText>

              <EnhancedAlert
                severity="info"
                sx={{
                  marginTop: 2,
                }}
                title="Important Info"
                v3Style
              >
                All district administrators and directors who are currently
                signed in to {SHORT_APP_NAME} will automatically be signed out at 2:00 AM CST.
              </EnhancedAlert>

              <EnhancedAlert
                severity="warning"
                sx={{
                  marginTop: 2,
                }}
                title="Warning"
                v3Style
              >
                <Box sx={{ marginBottom: 1.5 }}>
                  Any director in your system who is not an employee in your district will be
                  locked out of {SHORT_APP_NAME} unless they can sign in
                  with ClassLink as an employee.
                </Box>

                <Box>
                  For example, any booster volunteers that have
                  a director role in Presto will be locked out.
                </Box>
              </EnhancedAlert>
            </>
          )}
        handleClose={handleToggleIsEnableSsoDialogOpen}
        isConfirmButtonDisabled={!parsedToken?.ssoTenantId}
        isSubmitting={isUpdatingSsoTenantId}
        open={isEnableSsoDialogOpen && !isSsoPromptDialogOpen && Boolean(parsedToken?.ssoTenantId)}
        title={shouldPresentEnabledInDialog ? 'Disable ClassLink SSO?' : 'Enable ClassLink SSO?'}
      />
    </>
  );
};

export default ClassLinkSsoSetting;
