// External Dependencies
import { useQuery } from '@tanstack/react-query';

// Internal Dependencies
import { apiClient } from 'utils/apiClient';

export const useGetSchools = () => {
  return useQuery({
    queryFn: () => {
      return apiClient.v1.classLink.schools();
    },
    queryKey: ['classLinkSchools'],
  });
};

export const useGetStudentsForSchool = (schoolId: string) => {
  return useQuery({
    queryFn: () => {
      return apiClient.v1.classLink.studentsForSchool({ schoolId });
    },
    queryKey: ['classLinkStudentsForSchool', schoolId],
  });
};
