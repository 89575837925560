// External Dependencies
import {
  ClassLinkSsoPayload,
  ClassLinkTokenPayload,
} from '@presto-assistant/api_types/api/auth';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

// Internal Dependencies
import { apiClient } from 'utils/apiClient';
import { getSearchWithRedirectUrl } from 'utils/lib/redirect_user';
import { parseSearch } from 'utils/index';
import { setTokenInStorage } from 'utils/cookies';
import { stringifyQueryParams } from 'utils/lib/stringify_query_params';
import { toggleOrganization, useTanstackMutation } from 'utils/api/index';
import { useParsedSearch } from 'hooks/useParsedSearch';

export const getRedirectPath = () => {
  const queryString = `?${getSearchWithRedirectUrl()}`;

  const parsedSearch = parseSearch(queryString);

  if (parsedSearch.redirectUrl && parsedSearch.redirectUrl !== window.location.pathname) {
    return parsedSearch.redirectUrl;
  }

  return '/';
};

export const dfaMemberIdPlaceholder = 'admin';

export const useClassLinkToken = () => {
  const mutationFn = useCallback((payload: ClassLinkTokenPayload) => {
    return apiClient.auth.classLinkToken(payload);
  }, []);

  const dispatch = useDispatch();

  const parsedSearch = useParsedSearch();

  return useTanstackMutation({
    mutationFn,
    onSuccess: async (res) => {
      const token = res.headers['x-access-token'];

      if (token) {
        setTokenInStorage(token);
      }

      let {
        redirectPath = '/',
      } = parsedSearch;

      const {
        memberId,
        ...rest
      } = parsedSearch;

      delete parsedSearch.redirectPath;
      delete parsedSearch.memberId;

      const searchString = stringifyQueryParams(rest);

      redirectPath = `${redirectPath}${searchString ? `?${searchString}` : ''}`;

      if (memberId) {
        await toggleOrganization(
          memberId === dfaMemberIdPlaceholder ? null : memberId,
          dispatch,
          redirectPath,
        );
      } else {
        window.location.href = redirectPath;
      }
    },
  });
};

export const useClassLinkSso = () => {
  const mutationFn = useCallback((payload: ClassLinkSsoPayload) => {
    return apiClient.auth.classLinkSso(payload);
  }, []);

  return useTanstackMutation({
    mutationFn,
    onSuccess: (res) => {
      window.location.href = res.data.url;
    },
  });
};
